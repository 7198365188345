/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import Svg from 'components/Svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dropdown from 'images/dropdown.svg';
import StateCategoryRedSvg from 'images/stateCategoryRed.svg';
import shipmentsExportLimit from 'constants/shipmentsExport';
import { exportCsv } from 'helpers/exportCsv';
import { hasPermission } from 'helpers/auth';
import { connect } from 'react-redux';
import { showSpinner, closeSpinner } from 'actions';
import { event as gaEvent } from 'react-ga';
import styles from './DownloadCsvButton.scss';

const exportOptions = [
  { label: 'Export as CSV file', value: 'ShipmentSummaries' },
  { label: 'Export all data as CSV file', value: 'FullShipments' },
];

const DownloadCsvButton = (props) => {
  const [csvExportOption, setCsvExportOption] = useState(() => {
    const savedOption = localStorage.getItem('csvExportOption');
    return savedOption || exportOptions[0].label;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const savedOption = localStorage.getItem('csvExportOption');
    if (savedOption) {
      setCsvExportOption(savedOption);
    }
  }, []);

  const fitsQueryLimit = () => props.expectedQuerySize <= shipmentsExportLimit.limit;

  const getExportValue = (label) => {
    const selectedOption = exportOptions.find(option => option.label === label);
    return selectedOption ? selectedOption.value : exportOptions[0].value;
  };

  const handleClick = () => {
    if (isLoading || !fitsQueryLimit()) {
      return;
    }

    gaEvent({
      category: 'Reporting',
      action: 'Export shipments from table',
    });

    setIsLoading(true);
    setShowError(false);

    const csvExportValue = getExportValue(csvExportOption);

    exportCsv(props.searchQuery, props.shipmentStates, null, null, csvExportValue)
      .catch(() => {
        setIsLoading(false);
        setShowError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const selectOption = (option) => {
    setCsvExportOption(option);
    setShowDropdown(false);
    localStorage.setItem('csvExportOption', option);
  };

  const feedbackIcon = isLoading ? (
    <span className={styles.spinnerExportIcon}>
      <CircularProgress thickness={8} color="inherit" size={15} variant="indeterminate" />
    </span>
  ) : (
    <Svg className={classNames(styles.iconSVG, props.totalShipmentsCount >= shipmentsExportLimit.limit ? styles.Greysvg : null)} name="newCSVExport" />
  );

  return (
    hasPermission('shipment-operations:export') && (
      <div
        className={classNames(styles.container, props.totalShipmentsCount >= shipmentsExportLimit.limit ? styles.greyedOut : null)}
        role="button"
        disabled={isLoading || props.totalShipmentsCount >= shipmentsExportLimit.limit}
        {...(props.totalShipmentsCount >= shipmentsExportLimit.limit ? { 'data-tip': shipmentsExportLimit.message } : {})}
      >
        {showError && !props.totalShipmentsCount >= shipmentsExportLimit.limit && (
          <div data-tip="There was a problem exporting your shipments.">
            <div className={styles.error}>
              <StateCategoryRedSvg />
            </div>
          </div>
        )}

        {feedbackIcon}

        <div className={classNames(styles.buttonWrapper, { [styles.isLoading]: isLoading })}>
          <button
            type="button"
            className={styles.exportButton}
            onClick={handleClick}
            disabled={props.totalShipmentsCount >= shipmentsExportLimit.limit}
          >
            <label className={styles.label}>{csvExportOption}</label>
          </button>
        </div>

        <div className={styles.buttonWrapper}>
          <div className={classNames(styles.dropdownWrapper, { [styles.isLoading]: isLoading })}>
            <button
              type="button"
              className={styles.dropdownButton}
              onClick={toggleDropdown}
              disabled={props.totalShipmentsCount >= shipmentsExportLimit.limit}
            >
              <Dropdown className={`${styles.dropdownIcon} ${showDropdown && styles.dropDownIconOpen}`} alt="Dropdown icon" />
            </button>
          </div>

          {showDropdown && (
            <ul className={styles.dropdownMenu} role="menu">
              {exportOptions.map(opt => (
                <li
                  key={opt.value}
                  role="menuitem"
                  className={styles.dropdownOption}
                  tabIndex={0}
                  onClick={() => selectOption(opt.label)}
                  onKeyDown={(e) => e.key === 'Enter' && selectOption(opt.label)}
                >
                  {opt.label}
                </li>
              ))}
            </ul>
          )}
        </div>

        {props.totalShipmentsCount >= shipmentsExportLimit.limit && <ReactTooltip type="dark" border effect="solid" multiline />}
      </div>
    )
  );
};

DownloadCsvButton.propTypes = {
  searchQuery: propTypes.string.isRequired,
  shipmentReferences: propTypes.arrayOf(propTypes.string),
  expectedQuerySize: propTypes.number,
};

DownloadCsvButton.defaultProps = {
  shipmentReferences: [],
  expectedQuerySize: 0,
};

export default connect(null, {
  showSpinner,
  closeSpinner,
})(DownloadCsvButton);
