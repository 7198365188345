import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { event as gaEvent } from 'react-ga';
import format from 'date-fns/format';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Filters from 'components/Filters';
import { parseFiltersSearchQuery } from 'helpers/parseFiltersSearchQuery';
import joinQueries from 'helpers/joinQueryStrings';
import { getSelectedFilters } from 'helpers/selectedFilters';
import FilterSummary from 'components/FilterSummary';
import Spinner from 'components/Spinner';
import classnames from 'classnames';
import Paper from '@material-ui/core/Paper';
import {
  closePopup as closePopupAction, getAvailableCarriers,
  getShipments as getShipmentsAction,
  showPopup as showPopupAction,
} from 'actions';
import BackButton from 'components/BackButton';
import calculatedEvents from 'constants/calculatedEvents';
import TablePaginator from 'components/TablePaginator';
import DownloadCsvButton from './DownloadCsvButton';
import styles from './styles.scss';
import typography from '../../../../helpers/appTypography';
import AddBtnRedesigned from '../../../../components/AddFiltersBtnRedesigned/AddBtnRedesigned';

function mergeStateFilters(searchQuery) {
  const urlParams = new URLSearchParams(searchQuery);
  const shipmentStateFilter = urlParams.get('shipmentStateFilter');
  if (shipmentStateFilter) {
    urlParams.delete('states');
    urlParams.append('states', shipmentStateFilter);
  }
  const calculatedEventFilter = urlParams.get('calculatedEvent');
  if (calculatedEventFilter) {
    urlParams.delete('late');
    urlParams.delete('mayBeMissing');
    if (calculatedEventFilter === calculatedEvents.late) {
      urlParams.append('late', 'true');
    } else if (calculatedEventFilter === calculatedEvents.mayBeMissing) {
      urlParams.append('mayBeMissing', 'true');
    }
  }
  return urlParams.toString();
}

export class CSummary extends React.Component {
  columns = [
    {
      // 'Reference' is the first from custom_references and NOT Sorted reference. as told in work-item
      Header: 'Reference',
      id: 'custom_references',
      accessor: item => item.custom_references[0],
      maxWidth: 350,
    },
    {
      Header: 'Tracking Reference',
      accessor: 'reference',
      maxWidth: 230,
    },
    {
      Header: 'Carrier',
      accessor: 'carrier',
      minWidth: 80,
      maxWidth: 130,
    },
    {
      Header: 'Date Shipped',
      Cell: cellInfo => this.prettyDate(cellInfo.value),
      accessor: 'ship_date',
      maxWidth: 130,
      minWidth: 90,
    },
    {
      Header: 'Date Delivered',
      Cell: cellInfo => this.prettyDate(cellInfo.value),
      accessor: 'delivery_date',
      maxWidth: 130,
      minWidth: 90,
    },
    {
      Header: 'Address',
      id: 'address',
      accessor: item => (item.address),
      Cell: ({ value }) => (
        <div>
          <div className={styles.addressLine}>{value[0]}{value[1] && ', '}{value[1]}</div>
          <div className={styles.addressLine}>{value[2]}</div>
        </div>
      ),
    },
    {
      Header: 'State',
      accessor: 'state',
      maxWidth: 140,
      Cell: ({ value }) => (
        <span className={styles.stateLabel}>
          {value && value.replace(/(?!^)([A-Z]+)/g, ' $1').toLowerCase()}
        </span>
      ),
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      filtersOpen: false,
    };
  }

  componentDidMount() {
    this.props.getAvailableCarriers();
    this.getData();
  }

  // componentWillRecieveProps is deprecated and we need to fix this
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { searchQuery, getShipments } = this.props;
    if (nextProps.searchQuery !== searchQuery) {
      const filters = mergeStateFilters(nextProps.searchQuery);
      getShipments(filters, this.shipmentIds);
    }
  }

  get shipmentIds() {
    return this.props.location.state ? this.props.location.state.shipmentIds : null;
  }

  getSorting = ({ sort }) => {
    if (sort) {
      return (
        sort.charAt(0) === '-'
          ? [{
            desc: true,
            id: sort.substr(1),
          }]
          : [{
            desc: false,
            id: sort,
          }]
      );
    }
    return [];
  };

  getData() {
    const {
      getShipments,
    } = this.props;

    let { searchQuery } = this.props;
    if (getSelectedFilters() && !searchQuery) {
      searchQuery = getSelectedFilters();
      this.handleSubmit(searchQuery);
    }

    const filters = mergeStateFilters(searchQuery);
    getShipments(filters, this.shipmentIds);

    // we need it here in order to fix default
    // react-table behaviour
    // eslint-disable-next-line react/no-find-dom-node
    this.domNode = ReactDOM.findDOMNode(this);
  }

  prettyDate = date => (date ? format(date, 'DD/MM/YY') : '');

  handlePageChange = (newPage) => {
    const { redirect, location: { state }, searchQuery } = this.props;
    if (this.domNode) {
      this.domNode.scrollIntoView();
    }
    return redirect(state, searchQuery, `page=${+newPage + 1}`);
  };

  handlePageSizeChange = (newPageSize) => {
    const { redirect, searchQuery, location: { state } } = this.props;
    return redirect(state, searchQuery, `pageSize=${newPageSize}`, 'page=1');
  };

  handleSortChange = (state) => {
    const [rowToSort] = state;
    const { redirect, searchQuery, location } = this.props;
    gaEvent({
      category: 'Shipments Table',
      action: rowToSort.id,
    });
    const rowsToPreventSort = ['custom_references', 'address'];
    if (rowsToPreventSort.includes(rowToSort.id)) return false;
    return redirect(location.state, searchQuery, `sort=${rowToSort.desc ? '-' : ''}${rowToSort.id}`);
  };

  handleSubmit = searchQuery => this.props.redirect(
    this.props.location.state,
    searchQuery,
    this.props.search.page ? 'page=1' : '',
    this.props.search.pageSize ? `pageSize=${this.props.search.pageSize}` : '',
    this.props.search.sort ? `sort=${this.props.search.sort}` : '',
    this.props.search.searchString ? `searchString=${this.props.search.searchString}` : '',
  );

  handleRetry = () => {
    this.getData();
  };

  handleBackButtonClick = () => {
    window.history.back();
  };

  render() {
    const {
      isLoading,
      showPopup,
      closePopup,
      searchQuery,
      search,
      pageSize,
      totalPages,
      totalShipments: totalShipmentsCount,
      page,
      shipments,
      requestDidTimeout,
    } = this.props;

    let shipmentsOnPage = pageSize;
    const hasShipments = shipments && shipments.length > 0;
    if (hasShipments) {
      if (page === totalPages) {
        shipmentsOnPage = totalShipmentsCount - (pageSize * (page - 1));
      } else if (totalPages === 1) {
        shipmentsOnPage = totalShipmentsCount;
      }
    }
    return (
      <div>
        {isLoading ? <Spinner /> : null}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <BackButton onBack={this.handleBackButtonClick} />
            <h1 className="h2" style={{ margin: 0 }}>{typography.shipments.title} <span className={styles.spanTitleSecondText}>({totalShipmentsCount})</span></h1>
            <div style={{ margin: '0 0 20px 0' }}>{typography.shipments.subTitle}</div>
          </div>

          <DownloadCsvButton
            searchQuery={searchQuery}
            shipmentReferences={this.shipmentIds}
            totalShipmentsCount={totalShipmentsCount}
          />
        </div>

        <div className={classnames(styles.outerWrapper, !hasShipments && !requestDidTimeout && styles.noShipmentsOuterWrapper)}>
          <AddBtnRedesigned
            onClick={() => this.setState({ filtersOpen: true })}
          >+ Add filters
          </AddBtnRedesigned>
          <FilterSummary />
        </div>
        {hasShipments
          && (
            <Paper elevation={0} className={styles.innerContainer}>
              <div style={{ width: '100%' }}>
                <ReactTable
                  className={classnames(
                    'toBottom',
                    'toLeft',
                    'toRight',
                  )}
                  data={shipments}
                  columns={this.columns}
                  page={page - 1}
                  onPageChange={this.handlePageChange}
                  pages={totalPages}
                  pageSize={pageSize}
                  showPageSizeOptions={false}
                  pageSizeOptions={[20, 50, 100, 200]}
                  defaultPageSize={200}
                  onPageSizeChange={this.handlePageSizeChange}
                  minRows={shipmentsOnPage}
                  sorted={this.getSorting(search)}
                  onSortedChange={this.handleSortChange}
                  multiSort={false}
                  loading={isLoading}
                  PaginationComponent={TablePaginator}
                  totalRows={totalShipmentsCount}
                  footerText="shipments"
                  manual
                  trackingComponentName="Registered Shipments Table"
                  getTrProps={(rowState, rowInfo) => ({
                    onClick: () => {
                      gaEvent({
                        category: 'Registered Shipments Table',
                        action: 'Selected a shipment',
                      });
                      this.props.history.push(`/shipments/${rowInfo.original.id}`);
                    },
                  })}
                />
              </div>
            </Paper>
          )}
        {!hasShipments && !requestDidTimeout && <p>There were no shipments found based on filters applied</p>}
        {requestDidTimeout
          && (
            <>
              <p>We are temporarily unable to load shipments. Please try again later.</p>
              <div
                className={styles.button}
                onClick={this.handleRetry}
                role="button"
              >
                Retry
              </div>
            </>
          )}
        <div className={classnames(styles.filtersContainer, this.state.filtersOpen ? styles.open : styles.closed)}>
          {this.state.filtersOpen && (
          <Filters
            showPopup={showPopup}
            closePopup={closePopup}
            onSubmit={this.handleSubmit}
            closeFilters={() => this.setState({ filtersOpen: false })}
          />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ shipments }, { location }) => ({
  shipments: shipments.shipments,
  isLoading: shipments.loading,
  page: shipments.page,
  pageSize: shipments.pageSize,
  totalPages: shipments.totalPages,
  totalShipments: shipments.totalShipments,
  requestDidTimeout: shipments.requestDidTimeout,
  searchQuery: location.search,
  search: parseFiltersSearchQuery(location.search),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getShipments: (searchQuery, ids) => dispatch(getShipmentsAction(searchQuery, ids)),
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
  getAvailableCarriers: () => dispatch(getAvailableCarriers()),
  redirect: (state, ...args) => ownProps.history.push({ search: joinQueries(...args), state }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CSummary));
